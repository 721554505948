import {useRouter} from 'next/dist/client/router';
import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import FacilityCta from './FacilityCta';
import FacilitySpotlightCta from './FacilitySpotlightCta';
import FacilityCardSlider from '../FacilityCardSlider';
import {BREAKPOINT} from 'aac-components/utils/styles';
import SegmentCta from '../SegmentCta';

/* ABTasty Campaign Imports */
import ABTest from '../ABTest';
import Variation1 from '../AAC2-6973/Variation1';
import Variation2 from '../AAC2-6973/Variation2';
import Variation3 from '../AAC2-6973/Variation3';
import Variation4 from '../AAC2-6973/Variation4';

const InContentCta = (props) => {
    const {type, data, facility} = props;
    const {acf} = data;

    const {segment_cta: hideSegmentCta = false, facility_cta: hideFacilityCta = false} =
        acf?.hide_in_content_ctas || {};

    const {asPath} = useRouter();
    const [element, setElement] = useState(null);

    const isOutpatient = asPath?.includes('outpatient');
    const FacCtaComponent = isOutpatient
        ? OutpatientFacCardSlider
        : !hideFacilityCta
        ? FacilityCta
        : null;

    const CTA = () => {
        const isLocalPage = asPath?.includes('/treatment-centers');

        if (type === 'segment') {
            // above 3rd h2
            return isLocalPage ? (
                <FacilitySpotlightCta />
            ) : (
                !hideSegmentCta && <SegmentCta pageData={data} />
            );
        } else {
            // above 4th h2
            return !isLocalPage && !!facility ? (
                <FacilitySpotlightCta facility={facility} />
            ) : (
                // <FacCtaComponent />
                <ABTest
                    id="AAC2-6973"
                    Control={() => (FacCtaComponent ? <FacCtaComponent /> : null)}
                    variations={[
                        () => <Variation1 />,
                        () => <Variation2 />,
                        () => <Variation3 />,
                        () => <Variation4 />,
                    ]}
                />
            );
        }
    };

    const id = type === 'segment' ? 'content-cta__segment' : 'content-cta__facilities';

    let position = type === 'segment' ? 2 : 3;

    const appendCta = () => {
        const newDiv = document.createElement('div');

        // don't add a new div if already on the page
        newDiv.id = id;
        const ctas = document.querySelectorAll(`#${id}`);
        if (ctas.length > 0) return;

        const elements = Array.from(
            document.querySelectorAll('div:not(.modal-content) > h2'),
        );

        // do not render the facility map if lenght of h2's is less than 3
        if (id === 'content-cta__facilities' && elements.length < 3) {
            return;
        }

        // for segment cta, add above end h2 if there's less than 3
        position = elements?.length <= position && type === 'segment' ? 1 : position;

        if (elements.length > position) {
            // Append cta in the middle of content
            elements[position]?.before(newDiv);
        } else {
            // else append at the end of the content
            const articleElement = document.getElementById('wordpress-content');
            articleElement.appendChild(newDiv);
        }

        setElement(newDiv);
    };
    useEffect(() => {
        appendCta();
    }, [asPath]);

    return (
        <div>{element && ReactDOM.createPortal(<CTA asPath={asPath} />, element)}</div>
    );
};
export default React.memo(InContentCta);

const OutpatientFacCardSlider = () => {
    return (
        <div className="container">
            <h2>Our Outpatient Facilities Nationwide</h2>
            <FacilityCardSlider isOutpatient={true} />
            <style jsx>
                {`
                    .container {
                        margin: 40px 0;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .container {
                            margin: 80px 0;
                        }
                    }
                    h2 {
                        text-align: center;
                    }
                `}
            </style>
        </div>
    );
};
