import {useContext} from 'react';
import Image from 'next/image';
import Button from 'aac-components/components/Button';
import {
    FacilitySliderCtaContainerV4Styled,
    H2V4Styled,
    TextV4Styled,
    ButtonV4Styled,
    ImageContainerStyled,
} from './FacilitySliderCtaStyles';
import AppContext from '../AppContext';

const Variation4 = () => {
    const {isMobile} = useContext(AppContext);
    return (
        <FacilitySliderCtaContainerV4Styled>
            <ImageContainerStyled>
                <Image
                    src={
                        isMobile
                            ? '/static/Travel-for-rehab-background-mobile-min.jpg'
                            : '/static/Travel-for-rehab-background-min.jpg'
                    }
                    fill
                    alt="American Addiction Centers Image"
                    sizes="100vw"
                    style={{opacity: '.8', zIndex: -1}}
                />
            </ImageContainerStyled>
            <H2V4Styled>The Right Treatment Might Be Away From Home.</H2V4Styled>
            <TextV4Styled>
                Traveling for rehab can be a fresh start in a supportive, healing
                environment. Discover expert care at one of AAC’s top facilities
                nationwide and begin your recovery today.
            </TextV4Styled>
            <ButtonV4Styled>
                <Button title="Find a Treatment Center Today" href="/treatment-centers">
                    Find a Treatment Center Today
                </Button>
            </ButtonV4Styled>
        </FacilitySliderCtaContainerV4Styled>
    );
};

export default Variation4;
