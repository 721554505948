import React, {useContext} from 'react';
import AppContext from '../AppContext';
import IconArrowLong from 'aac-components/components/Icons/Arrow/IconArrowLong';
import {useRouter} from 'next/router';
import {useEffect, useState} from 'react';
import {getSegmentNumber} from '../../lib/utils';
import {BREAKPOINT} from 'aac-components/utils/styles';

// use formElementIndex when there are multiple forms on a page, ie for a test or something
const FormScrollback = ({type = 'vob', formElementIndex = 0}) => {
    const {asPath} = useRouter();

    const [formElement, setFormElement] = useState(null);

    const isValidElement = formElement && typeof formElement === 'object';

    const {isMobile} = useContext(AppContext);

    useEffect(() => {
        // delay to allow time for portals to render
        const delay = setTimeout(() => {
            let element = null;

            if (type === 'sms') {
                element =
                    document &&
                    document.querySelectorAll('[data-automation=aktify]')?.[
                        formElementIndex
                    ];
            } else if (type === 'contact-us-page') {
                element =
                    document && document.querySelector('.page-content #contact-form');
            } else {
                const segmentNumber = getSegmentNumber();
                const form =
                    document &&
                    document.querySelectorAll('body > div:not(#modal-root) #vob-form')?.[
                        formElementIndex
                    ];

                const cta =
                    document &&
                    document.getElementsByClassName('segmented__vob-cta')?.[
                        formElementIndex
                    ];

                // scroll to multi-step if there is one, otherwise use the segmented cta in lower segments
                element = form ? form : segmentNumber <= 2 && cta ? cta : null;
            }

            setFormElement(element);
        }, 3000);
        return () => clearTimeout(delay);
    }, [asPath]);

    const handleClick = () => {
        // handles scroll to for contact-us page only
        if (type === 'contact-us-page') {
            if (isMobile) {
                formElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                });
            } else {
                document.body.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                });
            }
        }
        // other scrolls
        else {
            isValidElement &&
                formElement?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                });
        }

        // open for the user if scrolling back to the segmented vob cta in low segments
        if (formElement?.className === 'segmented__vob-cta') {
            const delay = setTimeout(() => {
                window && window?.AAC?.openSlideInSsvob();
            }, 2000);
            return () => clearTimeout(delay);
        }
    };

    if (!isValidElement) return null;

    return (
        <div className={`form-scrollback form-type__${type}`}>
            <button onClick={handleClick}>
                Jump back to form{' '}
                <IconArrowLong
                    rotate="180"
                    fill="var(--interactive-300)"
                    width="18"
                    height="18"
                />
            </button>
            <style jsx>
                {`
                    .form-scrollback {
                        margin: 40px auto;
                        width: auto;
                        padding: 0 15px;
                    }
                    button {
                        color: var(--interactive-300);
                        background: #fff;
                        border: 2px solid var(--interactive-300);
                        border-radius: 8px;
                        font-size: 16px;
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        grid-gap: 4px;
                        min-width: 100%;
                        text-align: center;
                        justify-content: center;
                        margin: 0 auto;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        button {
                            min-width: fit-content;
                        }
                    }
                    button:hover {
                        background: var(--interactive-100);
                    }
                `}
            </style>
        </div>
    );
};
export default FormScrollback;
